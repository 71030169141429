(function () {
  'use strict';

  /* @ngdoc object
   * @name ecs
   * @description
   *
   */
  angular
    .module('ecs', [
      'ngAria',
      'ngMaterial',
      'ui.router',
      'wbNavigation',
      'lodash',
      'moment',
      'tmh.dynamicLocale',
      'pascalprecht.translate',
      'ngSanitize',
      'wbResource',
      'wbAuth',
      'wbAccount',
      'wbCta',
      'angularDateInterceptor',
      'wbErrorHandler',
      'wbAcceptLanguage',
      'angular-loading-bar',
      'wbOrder',
      'wbFaq',
      'isoCurrency',
      'wbBanner',
      'wbRestriction',
      'wbForm',
      'wbLoggedUser',
      'angulartics',
      'angulartics.google.analytics',
      'wbTimedInformationDialog',
      'wbGdpr'
    ])
    .run(setLocale)
    .run(activateLogs)
    .run(scrollToTop);

  function activateLogs($log, $rootScope, _) {
    $log.debug('APPLICATION START');
    $rootScope.$on('$destroy', function () {
      $log.debug('APPLICATION STOP');
    });

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState) {
      $log.debug('$stateChangeSuccess from: %s(%s) to: %s(%s)',
        fromState.name, fromState.url, toState.name, toState.url);
    });

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {
      $log.debug('$stateChangeStart from: %s(%s) to: %s(%s)',
        fromState.name, fromState.url, toState.name, toState.url);
    });

    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
      $log.error('$stateChangeError from: %s(%s) to: %s(%s), error: %s',
        fromState.name, fromState.url, toState.name, toState.url, _.has(error, 'message') ? error.message : error);
    });
  }

  function setLocale(tmhDynamicLocale, appConfig, $translate) {
    tmhDynamicLocale.set($translate.use());
  }

  function scrollToTop($rootScope, $anchorScroll) {
    $rootScope.$on('$stateChangeSuccess', function () {
      $anchorScroll('pageTop');
    });
  }
}());
